/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { iterappBaseUrl } from '../iterappApi';

export const Login: React.FC = () => {
    let authUrl = `${iterappBaseUrl}/auth/github?next=${decodeURIComponent(
        window.location.pathname
    )}`;

    return (
        <main
            css={css`
                text-align: center;
                margin-top: 30vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                h1 {
                    font-size: 44px;
                    line-height: 1.5;
                    text-shadow: 0px 0px 5px #ccccde;
                }
                a {
                    line-height: 1.5;
                    font-size: 20px;
                    display: block;
                    text-decoration: none;
                    padding: 16px 16px;
                    background: #222;
                    color: white;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                }
                a:hover {
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                }
                .error {
                    padding: 16px;
                    display: block;
                    color: #d8000c;
                    background-color: #ffbaba;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                    margin-bottom: 16px;
                }
            `}
        >
            <h1>Iterapp</h1>
            {/* {{#each messages}}
            <div class="error">{{this}}</div>
            {{/each}} */}
            <a href={authUrl}>Login with github</a>
        </main>
    );
};
