/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { RouteComponentProps, Link, useParams } from '@reach/router';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { useApp, DeploymentT, CurrentDeploymentT } from './useApp';
import { Layout } from '../../components/Layout';
import { colors, h1, h2, h3 } from '../../style';

export const AppDeployments: FC<RouteComponentProps> = () => {
    let { gitName, gitOwner } = useParams();
    let { data: app, isLoading, error } = useApp({ gitName, gitOwner });

    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (isLoading || !app) {
        return <div>Loading...</div>;
    }

    return (
        <Layout>
            <h1 css={h1}>{app.git_name}</h1>
            <h2 css={h2}>Latest</h2>
            <DeploymentsContainer>
                {app.current_deployments.map(deployment => (
                    <CurrentDeployment deployment={deployment} key={deployment.id} />
                ))}
            </DeploymentsContainer>
            <h2 css={h2}>History</h2>
            <DeploymentsContainer>
                {app.deployments.map(deployment => (
                    <Deployment deployment={deployment} key={deployment.id} />
                ))}
            </DeploymentsContainer>
        </Layout>
    );
};

function getStatus(deployment: DeploymentT): string {
    // if (deployment.build_status !== 'success') {
    //     return `build ${deployment.build_status}`;
    // }

    return deployment.status;
}

const GridDiv: React.FC<{ gridArea: string }> = ({ gridArea, children }) => {
    return <div css={{ gridArea }}>{children}</div>;
};

export const CurrentDeployment: React.FC<{ deployment: CurrentDeploymentT }> = ({ deployment }) => {
    return (
        <DeploymentWrapper to={`deployments/${deployment.id}`}>
            <h3 css={{ fontWeight: 700 }}>{deployment.environment}</h3>
            <GridDiv gridArea="status">{getStatus(deployment)}</GridDiv>
            <GridDiv gridArea="date">
                {format(parseISO(deployment.created_at), 'dd.MM.yyyy')}
            </GridDiv>
            <GridDiv gridArea="domain">
                {deployment.domains?.map(domain => (
                    <a href={domain} target="_blank" rel="noopener noreferrer">
                        {domain}
                    </a>
                ))}
            </GridDiv>
            <GridDiv gridArea="git_ref">
                {deployment.git_ref}@{deployment.git_hash.substring(0, 7)}
            </GridDiv>
            <GridDiv gridArea="git_message">{deployment.git_message}</GridDiv>
        </DeploymentWrapper>
    );
};

export const Deployment: React.FC<{ deployment: DeploymentT }> = ({ deployment }) => {
    return (
        <DeploymentWrapper to={`deployments/${deployment.id}`}>
            <h3 css={{ fontWeight: 700 }}>{deployment.environment}</h3>
            <GridDiv gridArea="status">{deployment.status}</GridDiv>
            <GridDiv gridArea="date">
                {format(parseISO(deployment.created_at), 'dd.MM.yyyy')}
            </GridDiv>
            <GridDiv gridArea="git_ref">
                {deployment.git_ref}@{deployment.git_hash.substring(0, 7)}
            </GridDiv>
            <GridDiv gridArea="git_message">{deployment.git_message}</GridDiv>
        </DeploymentWrapper>
    );
};

const DeploymentWrapper = styled(Link)`
    color: inherit;
    text-decoration: none;
    background-color: ${colors.gray200};
    border-radius: 3px;
    margin: 16px 0;
    padding: 16px 24px;
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: auto;
    grid-gap: 8px;
    grid-template-areas:
        'env         git_ref     status      . date'
        'domain      domain      domain      . .'
        'git_message git_message git_message . .';

    :hover {
        background: ${colors.gray300};
    }
`;

const DeploymentsContainer = styled.div``;
