/** @jsxImportSource @emotion/react */

import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { codeFont, h1 } from '../style';
import { useDataApi } from '../utils/useDataApi';
import { NotFound } from '../pages/NotFound';
import { format, isSameDay } from 'date-fns';
import { AxiosError } from 'axios';
import { css } from '@emotion/react';
import { useApp } from '../pages/app/useApp';
import { iterappApi } from '../iterappApi';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Ansi from 'ansi-to-react';

type LogsProps = {
    deploymentId: string;
    gitOwner: string;
    gitName: string;
    endDate: Date;
    startDate: Date;
};

export const DeploymentLogs: FC<LogsProps> = ({
    deploymentId,
    gitOwner,
    gitName,
    endDate,
    startDate,
}) => {
    let { data: app, isLoading: appIsLoading, error: appError } = useApp({ gitName, gitOwner });

    let [deploymentRes] = useDataApi<DeploymentT>(`/api/v1/deployments/${deploymentId}`);

    if (deploymentRes.error) {
        return <div>{deploymentRes.error.toString()}</div>;
    }
    if (appError) {
        return <div>{appError.toString()}</div>;
    }

    if (deploymentRes.isLoading || appIsLoading || !app) {
        return <div>Loading...</div>;
    }

    let deployment = deploymentRes.data;

    if (deployment.git_name !== gitName || deployment.git_owner !== gitOwner) {
        // Since we are in app context, and the apove api always get the deployemt. Check that the git_name is correct
        return <NotFound />;
    }

    return (
        <>
            <h1 css={h1}>
                {app.git_name} / {deployment.environment}
            </h1>
            <Logs deploymentId={deployment.id} endDate={endDate} startDate={startDate} />
        </>
    );
};

async function fetchLogs(
    deploymentId: number,
    startDate: Date,
    endDate: Date,
    searchWord: String,
    appling: string
): Promise<LogsT> {
    let res = await iterappApi.get<LogsT>(`/api/v1/deployments/${deploymentId}/logs`, {
        params: {
            count: 2000,
            time_gt: startDate,
            time_lt: endDate,
            regex: searchWord,
            appling: appling.length > 0 ? appling : undefined,
        },
    });

    return res.data;
}

export const Logs: React.FC<{ deploymentId: number; startDate: Date; endDate: Date }> = ({
    deploymentId,
    startDate,
    endDate,
}) => {
    const [logsEndDate, setLogsEndDate] = useState<Date>(endDate);
    const [searchWord, setSearchWord] = useState<string>('');
    const [inputWord, setInputWord] = useState<string>(searchWord);
    const [applingToShow, setApplingtoShow] = useState<string>('');

    useEffect(() => {
        setLogsEndDate(endDate);
    }, [endDate]);

    const {
        data: loglines,
        isLoading,
        error,
    } = useQuery<LogsT, AxiosError>(
        ['logs', { deploymentId, startDate, logsEndDate, searchWord, applingToShow }],
        () => fetchLogs(deploymentId, startDate, logsEndDate, searchWord, applingToShow)
    );

    let [deploymentRes] = useDataApi<DeploymentT>(`/api/v1/deployments/${deploymentId}`);

    let containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [containerRef, isLoading]);

    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (isLoading || !loglines || deploymentRes.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div
                css={css`
                    display: flex;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    margin-right: 10px;
                `}
            >
                <button
                    css={css`
                        margin-left: 10px;
                    `}
                    disabled={!loglines[0]}
                    onClick={() => {
                        setLogsEndDate(new Date(loglines[0].time));
                    }}
                >
                    Previous logs
                </button>

                <form
                    css={css`
                        margin-left: 50px;
                    `}
                >
                    <input
                        type="text"
                        id="header-search"
                        placeholder="Search logs"
                        value={inputWord}
                        onChange={e => {
                            setInputWord(e.target.value);
                        }}
                    />
                    <button
                        type="submit"
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                        onClick={e => {
                            e.preventDefault();
                            setSearchWord(inputWord);
                        }}
                    >
                        Search
                    </button>
                    {inputWord !== '' && (
                        <button
                            type="reset"
                            onSubmit={e => {
                                e.preventDefault();
                            }}
                            onClick={e => {
                                e.preventDefault();
                                setInputWord('');
                                setSearchWord('');
                            }}
                        >
                            Reset
                        </button>
                    )}
                </form>
                {deploymentRes.data && (
                    <select
                        aria-label="Select applings to show"
                        onChange={val => setApplingtoShow(val.target.value)}
                        css={css`
                            margin-left: 50px;
                        `}
                    >
                        <option value="">All applings</option>
                        {Object.values(deploymentRes.data.build_pods).map((appling: any) => (
                            <option value={appling.appling} key={appling.appling}>
                                {appling.appling}
                            </option>
                        ))}
                    </select>
                )}
            </div>

            <div
                css={[
                    {
                        display: 'block',
                        whiteSpace: 'pre-wrap',
                        overflowY: 'auto',
                    },
                    codeFont,
                ]}
                ref={containerRef}
            >
                <div>
                    {loglines.length > 0 ? (
                        loglines.map((line, index) => (
                            <>
                                {(index === 0 ||
                                    !isSameDay(
                                        new Date(loglines[index].time),
                                        new Date(loglines[index - 1].time)
                                    )) && (
                                    <div
                                        css={css`
                                            font-size: 24px;
                                            line-height: 32px;
                                            position: sticky;
                                            top: 0px;
                                            background-color: rgba(27, 31, 35, 0.06);
                                            color: rgb(33, 41, 47);
                                            padding: 0 16px;
                                            font-weight: bold;
                                        `}
                                    >
                                        {format(new Date(line.time), 'dd.MM.yyyy')}
                                    </div>
                                )}
                                <div
                                    key={line.time}
                                    css={css`
                                        margin: 8px 0;
                                        display: flex;
                                        justify-content: space-between;
                                        background-color: #f6f8fa;
                                    `}
                                >
                                    <div
                                        css={css`
                                            color: #37474f;
                                        `}
                                    >
                                        <Ansi>{line.log}</Ansi>
                                    </div>
                                    <div
                                        css={css`
                                            color: rgb(153, 153, 153);
                                        `}
                                    >
                                        {format(new Date(line.time), 'HH:mm:ss')}
                                    </div>
                                </div>
                            </>
                        ))
                    ) : (
                        <p>No logs found</p>
                    )}
                </div>
            </div>
        </div>
    );
};

type LogsT = Array<{
    time: string;
    log: string;
    app: string;
    pod: string;
    namespace: string;
    container: string;
    host: string;
}>;

export type DeploymentT = {
    id: number;
    build_id: number;
    namespace: string;
    created_at: string;
    status: string;
    environment: string;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
    git_message?: string;
    // build_status: string;
    config_file: unknown;
    build_pods: Record<
        string,
        {
            id: number;
            status: string;
            appling: string;
            namespace: string;
            name: string;
            build_log: string;
            buildpod_namespace: string;
            buildpod_name: string;
        }
    >;
};
