/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Link } from '@reach/router';

export const Menu: FC = () => {
    return (
        <ul
            css={{
                background: '#374151',
                listStyleType: 'none',
                padding: '8px 16px',
                margin: 0,
                display: 'flex',
                flexDirection: 'row',
                '& > li': {
                    display: 'block',
                    marginRight: 16,
                    '& > a': {
                        color: '#fff',
                        textDecoration: 'none',
                        ':hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
            }}
        >
            <li>
                <Link css={{ fontWeight: 700 }} to="/">
                    Iterapp
                </Link>
            </li>
            <li>
                <Link to="/encrypt_secret">Encrypt Secret</Link>
            </li>
            <li>
                <Link to="/test_iterapp_toml">iterapp.toml playground</Link>
            </li>
            <li>
                <a href="/docs">Documentation</a>
            </li>
            <li>
                <a href="/settings/profile">Settings</a>
            </li>
        </ul>
    );
};
