/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Menu } from './Menu';

export const Layout: FC<{ fullWidth?: boolean }> = ({ children, fullWidth = false }) => {
    return (
        <div>
            <Menu />
            <div css={{ margin: 16 }}>{children}</div>
        </div>
    );
};
