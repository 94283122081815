import { Global, css } from '@emotion/react';

export const colors = {
    // Stealen from tailwind css
    black: '#111827',
    bg: '##F9FAFB',
    gray50: '#F9FAFB',
    gray100: '#F3F4F6',
    gray200: '#E5E7EB',
    gray300: '#D1D5DB',
    gray400: '#9CA3AF',
    gray500: '#6B7280',
    gray600: '#4B5563',
    gray700: '#374151',
    gray800: '#1F2937',
    gray900: '#111827',
    red600: '##DC2626',
    yellow600: '#D97706',
    green600: '#059669',
    blue600: '#2563EB',
    indigo900: '#312E81',
};

// Large headings
export const h1 = css`
    font-weight: normal;
    font-size: 40px;
    line-height: 64px;
`;

// Smaller headings
export const h2 = css`
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
`;

// title headings
export const h3 = css`
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
`;

// Most paragraphs
export const p1 = css`
    letter-spacing: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
`;

// Cards and sub-items
export const p2 = css`
    letter-spacing: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
`;

export const codeFont = css`
    font-family: 'Inconsolata', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`;

export const item = css`
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 16px 24px;
    background: ${colors.gray200};
    margin: 16px 0;
    border-radius: 3;
    :hover {
        background: ${colors.gray300};
    }

    &[data-active] {
        background: ${colors.gray700};
        color: white;
    }
`;

export const GlobalStyle: React.FC = () => (
    <Global
        styles={css`
            html {
                box-sizing: border-box;
                background: ${colors.bg};
            }
            *,
            *:before,
            *:after {
                box-sizing: inherit;
            }

            body {
                font-family: 'Source Sans Pro', sans-serif;
                ${p1};
                margin: 0;
                padding: 0;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: ${colors.black};
                background: ${colors.bg};
            }
            code {
                ${codeFont};
            }

            pre {
                ${codeFont};
            }
        `}
    />
);
