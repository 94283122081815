import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useDataApi } from '../utils/useDataApi';
import styled from '@emotion/styled';
import { Layout } from '../components/Layout';

type BuildT = {
    id: number;
    updated_at: string;
    created_at: string;
    created_by: string;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
};

export const Builds: React.FC<RouteComponentProps> = () => {
    let [builds] = useDataApi<Array<BuildT>>(`/api/v1/builds`);

    if (builds.isLoading) {
        return <div>Loading...</div>;
    }

    if (builds.error) {
        return <div>{builds.error.toString()}</div>;
    }

    return (
        <Layout>
            <BuildContainer>
                {builds.data.map(build => (
                    <Build build={build} key={build.id} />
                ))}
            </BuildContainer>
        </Layout>
    );
};

export const Build: React.FC<{ build: BuildT }> = ({ build }) => {
    return (
        <div>
            <div>
                <Link to={`/builds/${build.id}`}>Build #{build.id}</Link>
            </div>
            {/* <div>{build.status}</div> */}
            <div>Created: {build.created_at}</div>
            <div>Updated {build.updated_at}</div>
            <div>Created by: {build.created_by}</div>
            <div>
                <a
                    href={`https://github.com/${build.git_owner}/${build.git_name}/tree/${build.git_ref}`}
                >
                    {build.git_owner}/{build.git_name}#{build.git_ref}
                </a>
            </div>
            <div>
                <a
                    href={`https://github.com/${build.git_owner}/${build.git_name}/commit/${build.git_hash}`}
                >
                    {build.git_hash}
                </a>
            </div>
        </div>
    );
};

const BuildContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 500px));
`;
