import React, { FC, useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useDataApi } from '../utils/useDataApi';
import { Layout } from '../components/Layout';
import { Logs } from '../components/Logs';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import {
    useQuery,
    useQueryClient,
    useMutation,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import '@reach/tabs/styles.css';
import { iterappApi } from '../iterappApi';
import Ansi from 'ansi-to-react';

function PodStatus(props: { buildId: string; appling: number }) {
    const { buildId, appling } = props;
    const queryClient = useQueryClient();
    const [intervalMs, setIntervalMs] = React.useState(10000);
    const [isActive, setIsActive] = React.useState(true);
    const [podStatus, setPodStatus] = React.useState('');
    const [podIcon, setPodIcon] = React.useState('');

    const { status, data, error, isLoading } = useQuery<BuildT>(
        ['build'],
        async () => {
            return iterappApi.get(`/api/v1/builds/${buildId}`).then(res => res.data);
        },

        {
            // Refetch the data every second
            refetchInterval: intervalMs,
            // Disable query when finished
            enabled: isActive,
        }
    );

    useEffect(() => {
        status === 'success' &&
            data &&
            setPodStatus(Object.values(data.build_pods)[appling].status);
        if (podStatus === 'loading') {
            setPodIcon('🚧');
        }
        if (podStatus === 'waiting') {
            setPodIcon('🔜');
        }
        if (podStatus === 'in-progress') {
            setPodIcon('🕐');
        }
        if (podStatus === 'success') {
            setPodIcon('🎉');
            setIsActive(false);
            //JavaScript notification API
            new Notification('Build success 🎉', {
                body: 'Ha en fin dag videre',
            });
        }
        if (podStatus === 'failure') {
            setPodIcon('🔴');
            setIsActive(false);
            //JavaScript notification API
            new Notification('Build failed 🪲', {
                body: 'Sånt skjer..',
            });
        }
        const newFavicon = faviconTemplate(podIcon || '🤡');
        linkForFavicon?.setAttribute(`href`, `data:image/svg+xml,${newFavicon}`);
    }, [data, appling, buildId, status, podIcon, podStatus]);

    return <span>{podIcon}</span>;
}

export const Build: FC<{ buildId: string; applingName?: string } & RouteComponentProps> = ({
    buildId,
    applingName,
}) => {
    let [buildRes] = useDataApi<BuildT>(`/api/v1/builds/${buildId}`);

    if (buildRes.isLoading) {
        return <div>Loading...</div>;
    }

    if (buildRes.error) {
        return <div>{buildRes.error.toString()}</div>;
    }

    let build = buildRes.data;
    let tabIndex = Object.keys(build.build_pods).findIndex(name => name === applingName);
    if (tabIndex === -1) {
        tabIndex = 0;
    }

    return (
        <Layout>
            <div>Build #{build.id}</div>
            <Tabs
                index={tabIndex}
                onChange={newIndex => {
                    navigate(`/builds/${buildId}/${Object.keys(build.build_pods)[newIndex]}`);
                }}
            >
                <TabList>
                    {Object.keys(build.build_pods).map(name => (
                        <Tab key={name}>
                            {name}
                            <PodStatus buildId={buildId} appling={tabIndex} />
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {Object.values(build.build_pods).map(buildPod => (
                        <TabPanel key={buildPod.id}>
                            <div>{buildPod.status}</div>
                            <div>Created: {build.created_at}</div>
                            <div>Updated {build.updated_at}</div>
                            <div>Created by: {build.created_by}</div>
                            <div>
                                <a
                                    href={`https://github.com/${build.git_owner}/${build.git_name}/tree/${build.git_ref}`}
                                >
                                    {build.git_owner}/{build.git_name}#{build.git_ref}
                                </a>
                            </div>
                            <div>
                                <a
                                    href={`https://github.com/${build.git_owner}/${build.git_name}/commit/${build.git_hash}`}
                                >
                                    {build.git_hash}
                                </a>
                            </div>
                            {buildPod.status === 'in-progress' ? (
                                <Logs
                                    entity={{
                                        type: 'build',
                                        buildId: build.id,
                                        appling: buildPod.appling,
                                    }}
                                />
                            ) : (
                                <div>
                                    <pre>
                                        <Ansi>{buildPod.build_log}</Ansi>
                                    </pre>
                                </div>
                            )}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Layout>
    );
};

const linkForFavicon = document.querySelector(`head > link[rel='icon']`);

function faviconTemplate(icon: string) {
    return `
    <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
    <text y=%22.9em%22 font-size=%2290%22>
        ${icon}
    </text>
    </svg>
`.trim();
}
type PodInfo = {
    id: number;
    status: string;
    appling: string;
    namespace: string;
    name: string;
    build_log?: string;
};
type BuildT = {
    id: number;
    updated_at: string;
    created_at: string;
    created_by: string;
    // status: string;
    config_file: unknown;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
    build_pods: Record<string, PodInfo>;
};

export const asd = 123;
