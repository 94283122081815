import axios from 'axios';

export const iterappBaseUrl = (() => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:3000';
    }
    if (window.location.hostname === 'apps.iterapp.no') {
        return 'https://iterapp-server.iterate.iterapp.no';
    }
    let match = window.location.hostname.match(/apps.([^.]*).hops.run/);
    if (match) {
        return `https://iterapp-server.iterate.${match[1]}.hops.run`;
    }

    return 'https://iterapp-server.iterate.iterapp.no';
})();

export const iterappApi = axios.create({
    baseURL: iterappBaseUrl,
    withCredentials: true,
});
