import axios, { AxiosInstance, AxiosError } from 'axios';
import { useEffect, useReducer, Reducer, useCallback } from 'react';
import { iterappApi } from '../iterappApi';

// https://www.robinwieruch.de/react-hooks-fetch-data/

// T is the type of a normal (successful) response
// E is the type of an error response.  It can for example be string, null, or T.

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
    return axios.isAxiosError(error);
}

export const createUseDataApi =
    (apiClient: AxiosInstance) =>
    <T, E = unknown>(url: string | void): [State<T, E>, () => void] => {
        const reducer: Reducer<State<T, E>, Action<T, E>> = dataFetchReducer;

        const [state, dispatch] = useReducer(reducer, {
            isLoading: true,
            error: undefined,
            data: undefined,
        });

        useEffect(() => {
            dispatch({ type: 'FETCH_INIT' });
        }, []);

        const fetchData = useCallback(() => {
            let didCancel = false;

            const fetchData = async () => {
                try {
                    // In case the url is undefined, we don't do any fetch.
                    // As the fetch is automatically initiated, this makes it possible to delay
                    // the fetch until the url/options object is known.
                    const result = url ? await apiClient(url) : { data: null };

                    if (!didCancel) {
                        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                    }
                } catch (error: unknown) {
                    if (!didCancel && isAxiosError<E>(error)) {
                        console.error(error);
                        dispatch({ type: 'FETCH_FAILURE', error });
                    }
                }
            };

            fetchData();

            return () => {
                didCancel = true;
            };
        }, [url, dispatch]);

        useEffect(() => {
            dispatch({ type: 'FETCH_INIT' });
            return fetchData();
        }, [fetchData]);

        return [state, fetchData];
    };

export const useDataApi = createUseDataApi(iterappApi);

export type Action<T, E> =
    | {
          type: 'FETCH_INIT';
      }
    | {
          type: 'FETCH_SUCCESS';
          payload: T;
      }
    | {
          type: 'FETCH_FAILURE';
          error: AxiosError<E>;
      };

export type State<T, E> =
    | {
          isLoading: true;
          error: undefined;
          data: undefined;
      }
    | {
          isLoading: false;
          // Protip: Use error.response.data (has type E) to get the body of the error response.
          error: AxiosError<E>;
          data: undefined;
      }
    | {
          isLoading: false;
          error: undefined;
          data: T;
      };

const dataFetchReducer = <T, E>(state: State<T, E>, action: Action<T, E>): State<T, E> => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                isLoading: true,
                error: undefined,
                data: undefined,
            };
        case 'FETCH_SUCCESS':
            return {
                isLoading: false,
                error: undefined,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                isLoading: false,
                error: action.error,
                data: undefined,
            };
        default:
            throw new Error();
    }
};
