import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { iterappApi } from '../../iterappApi';

export type AppT = {
    id: number;
    description: string;
    git_name: string;
    git_owner: string;
    current_deployments: CurrentDeploymentT[];
    deployments: DeploymentT[];
};

export type DeploymentT = {
    id: number;
    build_id: number;
    created_at: string;
    status: string;
    environment: string;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
    git_message?: string;
    // build_status: string;
};

export type CurrentDeploymentT = DeploymentT & {
    domains?: string[];
    config_file: unknown;
};

async function fetchApp({ gitOwner, gitName }: { gitOwner: string; gitName: string }) {
    let res = await iterappApi.get<AppT>(`/api/v1/apps/${gitOwner}/${gitName}`);

    return res.data;
}

export function useApp({ gitOwner, gitName }: { gitOwner: string; gitName: string }) {
    return useQuery<AppT, AxiosError>(['app', { gitOwner, gitName }], () =>
        fetchApp({ gitOwner, gitName })
    );
}
