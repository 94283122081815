/** @jsxImportSource @emotion/react */
import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useDataApi } from '../utils/useDataApi';
import { Layout } from '../components/Layout';
import * as style from '../style';
import { css } from '@emotion/react';

type AppT = {
    id: number;
    description: string;
    git_name: string;
    git_owner: string;
    last_deploy: string;
};

export const Apps: React.FC<RouteComponentProps> = () => {
    let [apps] = useDataApi<Array<AppT>>(`/api/v1/apps`);
    const [filter, setFilter] = React.useState<string>('');
    const inputRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    if (apps.isLoading) {
        return <div>Loading...</div>;
    }

    if (apps.error) {
        return <div>{apps.error.toString()}</div>;
    }

    return (
        <Layout>
            <input
                css={{ fontSize: 'inherit', padding: 8, width: '100%' }}
                placeholder="Filter apps by name"
                type="text"
                value={filter}
                onChange={event => setFilter(event.target.value)}
                ref={inputRef}
            />
            <div>
                {apps.data
                    .filter(app => app.git_name.includes(filter) || app.git_owner.includes(filter))
                    .map(app => (
                        <App app={app} key={app.id} />
                    ))}
            </div>
        </Layout>
    );
};

export const App: React.FC<{ app: AppT }> = ({ app }) => {
    return (
        <Link
            css={css`
                ${style.item};
            `}
            to={`/apps/${app.git_owner}/${app.git_name}`}
        >
            {app.git_owner === 'iterate' ? app.git_name : `${app.git_owner}/${app.git_name}`}
        </Link>
    );
};
